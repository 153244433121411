<template>
  <div class="chart_tile ">
    <div class="chart_tile--name">
      <img v-if="data.image && showImage" :src=data.image :alt=data.name @error="onImageError"
           class="chart_tile--name-img">
      <div v-else class="chart_tile--name-img">
        {{ data.name[0] }}
      </div>
      <span class="text-font-main mx-3">{{ data.name }}</span>
    </div>

    <span class="chart_tile--count">
      {{  data.count }}
    {{ title!=null ? title : $t('common.order') }}
    </span>
    <span class="chart_tile--percent">
            {{ percent }}%
          <echart-pie-simple :data-value="percent" :data-color="color" ref="pieRef"/>
          </span>
  </div>
</template>

<script>
import EchartPieSimple from "@/views/pages/dashboard/charts/echart-pie-simple";

export default {
  name: "chart_tile",
  components: {EchartPieSimple},
  props: {
    color: String,
    total: [String, Number],
    title:String,
    chartRef:String,
    data: Object
  },
  data() {
    return {
      showImage: true
    }
  },
  computed: {
    percent() {
      return ((this.data.count / this.total) * 100).toFixed(2);
    }
  },
  methods: {

    onImageError() {
      this.showImage = false;
    },
  },

}
</script>

<style scoped>

</style>
